import { SliceZone } from "@prismicio/react";
import { components } from "slices";
import documentFooter from "assets/footer.json";

export const DesktopFooter = (): JSX.Element => {
    return (
        <footer className="flex flex-col items-center gap-8 bg-white py-12 lg:gap-16 lg:divide-x-0 lg:divide-y lg:divide-solid lg:divide-blue-grey-50 lg:py-16">
            <SliceZone
                slices={documentFooter.data.slices}
                components={components}
            />
        </footer>
    );
};
