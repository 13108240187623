import { LogomarkBlue, LogomarkWithNameBlue } from "assets/vectors/logo";
import Link from "next/link";
import { Button } from "./Button";
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogSheet,
    DialogTrigger,
} from "./DialogSheet";
import { CloseUnfilled, MenuAlt1Filled } from "./Icons";
import { Separator } from "./Separator";
import { ReactNode, useState } from "react";

export const DesktopHeaderNoProfile = (): JSX.Element => (
    <header className="sticky top-0 z-10 hidden bg-white px-4 text-blue-grey-900 shadow-md lg:block">
        <div className="mx-auto flex w-full max-w-[1056px] justify-between py-4">
            <nav className="flex items-center gap-8">
                <Link href="/">
                    <div className="cursor-pointer">
                        <LogomarkWithNameBlue />
                    </div>
                </Link>
                <Link href="/pricing" passHref>
                    <a className="typography-h4 remove-styles-a cursor-pointer rounded-[20px] border-none bg-transparent px-2.5 py-2 !text-current text-blue-grey-900 hover:bg-blue-grey-50 hover:no-underline focus:bg-blue-grey-20 focus:text-blue-grey-900 focus:outline focus:outline-1 focus:outline-blue-grey-100">
                        Pricing
                    </a>
                </Link>
            </nav>
            <nav className="flex items-center gap-4">
                <Link href="/login">
                    <a className="remove-styles-a typography-main cursor-pointer font-medium text-blue-grey-900">
                        Business Login
                    </a>
                </Link>
                <Link href="/demo" passHref>
                    <Button className="w-[117px]" asChild>
                        <a className="remove-styles-a">Book a Demo</a>
                    </Button>
                </Link>
            </nav>
        </div>
    </header>
);

export const MobileHeaderNoProfile = ({
    children,
}: {
    children?: ReactNode;
}): JSX.Element => (
    <header className="sticky top-0 z-10 bg-white shadow-md lg:hidden">
        <nav className="flex items-center justify-between p-4">
            <Link href="/">
                <div className="flex cursor-pointer items-center">
                    {children ? <LogomarkBlue /> : <LogomarkWithNameBlue />}
                </div>
            </Link>
            <div className="typography-h3 font-semibold text-blue-grey-900">
                {children}
            </div>
            <Dialog />
        </nav>
    </header>
);

const linkCss = "remove-styles-a px-4 py-6 text-blue-grey-900";
const Dialog = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const handleClose = (): void => setOpen(false);
    return (
        <DialogSheet open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <div className="flex items-center">
                    <MenuAlt1Filled className="size-7 text-blue-grey" />
                </div>
            </DialogTrigger>
            <DialogContent side="right">
                <div className="flex justify-end px-4">
                    <DialogClose>
                        <CloseUnfilled className="size-7 text-blue-grey-900" />
                    </DialogClose>
                </div>
                <DialogDescription className="typography-overline-lg flex flex-col py-8 text-blue-grey-900">
                    <Link href="/login">
                        <a className={linkCss} onClick={handleClose}>
                            Business Login
                        </a>
                    </Link>
                    <Separator className="my-4" />
                    <Link href="/pricing">
                        <a className={linkCss} onClick={handleClose}>
                            Pricing
                        </a>
                    </Link>
                    <Link href="/demo">
                        <a className={linkCss} onClick={handleClose}>
                            Book a Demo
                        </a>
                    </Link>
                </DialogDescription>
            </DialogContent>
        </DialogSheet>
    );
};
